
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "start-interview",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    //Create form validation object
    const addForm = Yup.object().shape({
      department: Yup.string()
        .required(t("validators_error.required"))
        .label("Department"),
      jobPosition: Yup.string()
        .required(t("validators_error.required"))
        .label("JobPosition"),
      reason: Yup.string()
        .required(t("validators_error.required"))
        .label("Reason"),
      experience: Yup.string()
        .required(t("validators_error.required"))
        .label("Experience"),
    });

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.startInterview.title"));
    });

    const submit = (values: any) => {
      console.log(values);
      router.push({ path: `/doInterview` });
    };

    return {
      addForm,
      submit
    };
  },
});
